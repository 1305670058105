import rpc from "@/rpc";

class LoanService {
    rpc

    constructor(rpc) {
        this.rpc = rpc
    }

    getLoan(id) {
        return this.rpc.execute('getLoan', {
            id: parseInt(id) == id ? parseInt(id) : id
        });
    }

    closeLoan(id) {
        return this.rpc.execute('closeLoan', {
            id: parseInt(id) == id ? parseInt(id) : id
        });
    }
}

export default new LoanService(rpc)